import { createSlice } from "@reduxjs/toolkit";
import { getItem, setItem } from "../../services/Storage.service";
import { AVATAR_KEY, ROOM_KEY, USERNAME_KEY } from "./index";
import { getAvatarWithDefault, IAvatar } from "../../services/Avatar.service";

const storedUsername = getItem(USERNAME_KEY);
const initialUsername =
  storedUsername !== null && storedUsername !== undefined ? storedUsername : "";

export const initialState = {
  rememberMe: true,
  username: initialUsername,
  roomId: getItem(ROOM_KEY) ?? "",
  avatar: getAvatarWithDefault(getItem(AVATAR_KEY) ?? undefined),
};

const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    changeRememberMe(state, action: { payload: boolean; type: string }) {
      state.rememberMe = action.payload;
    },
    changeUsername(state, action: { payload: string; type: string }) {
      const userNameToSet = action.payload;
      state.username = userNameToSet;
      if (state.rememberMe) {
        setItem(USERNAME_KEY, userNameToSet);
      }
    },
    changeRoomId(state, action: { payload: string; type: string }) {
      const roomToJoin = action.payload;
      setItem(ROOM_KEY, roomToJoin);
      state.roomId = roomToJoin;
    },
    changeAvatar(state, action: { payload: IAvatar; type: string }) {
      const avatarToSet: IAvatar = action.payload;
      if (state.rememberMe) {
        setItem(AVATAR_KEY, avatarToSet.id);
      }
      state.avatar = avatarToSet;
    },
  },
});

// `createSlice` automatically generated action creators with these names.
// export them as named exports from this "slice" file
export const { changeRememberMe, changeUsername, changeRoomId, changeAvatar } =
  registerSlice.actions;

// Export the slice reducer as the default export
export default registerSlice.reducer;
