import { notification } from "antd";
import { createNewUUID } from "./UUID";

/**
 *
 * @param type defaults to info
 * @param notificationPlacement defaults to bottomLeft
 * @param message
 * @param description
 * @param duration time in seconds
 * @param key
 * @param onCloseFunction
 */
export function openNotification(
  type: NotificationType,
  notificationPlacement?: NotificationPlacement,
  message?: string,
  description?: string,
  duration?: number,
  key?: string,
  onCloseFunction?: () => void
) {
  notification.open({
    message: message ? message : "",
    description: description ? description : "",
    key: key ? key : createNewUUID(),
    onClose: onCloseFunction ? onCloseFunction : onNotificationClose,
    duration: duration ? duration : 0,
    type: type,
    placement: notificationPlacement ? notificationPlacement : "bottomLeft",
  });
}

export enum NotificationType {
  info = "info",
  success = "success",
  warning = "warning",
  error = "error",
}

export enum NotificationPlacement {
  topLeft = "topLeft",
  topRight = "topRight",
  bottomLeft = "bottomLeft",
  bottomRight = "bottomRight",
}

export function onNotificationClose() {}
