import man1 from "../images/avatars/man (1).jpg";
import man2 from "../images/avatars/man (2).jpg";
import man3 from "../images/avatars/man (3).jpg";
import girl1 from "../images/avatars/girl (1).jpg";
import girl2 from "../images/avatars/girl (2).jpg";
import girl3 from "../images/avatars/girl (3).jpg";

export interface IAvatar {
  id: string;
  image: any;
}

export const PROFILE_IMAGES: IAvatar[] = [
  {
    id: "1c3be492-2caf-40ad-9d9b-28a1c3ec6a91",
    image: man1,
  },
  {
    id: "ce8953b8-5144-440b-8d9a-78727647dc08",
    image: man2,
  },
  {
    id: "c01f78cd-e95f-41cd-84b9-307eeba279ed",
    image: man3,
  },
  {
    id: "9947cbd3-96b8-4712-aad7-13e5e4df2ef8",
    image: girl1,
  },
  {
    id: "9f6ffe9b-9956-4554-9bfb-fd9761569527",
    image: girl2,
  },
  {
    id: "866c11f6-0d6d-45f5-b852-af5d3de8aad4",
    image: girl3,
  },
];

export function findAvatarById(id: string): IAvatar | undefined {
  return PROFILE_IMAGES.find((profileImage) => profileImage.id === id);
}

export function getDefaultAvatar() {
  return PROFILE_IMAGES[0];
}

export function getAvatarWithDefault(storedAvatarId: string | undefined) {
  if (storedAvatarId) {
    const foundProfileImage = findAvatarById(storedAvatarId);
    if (foundProfileImage !== undefined) {
      return foundProfileImage;
    }
  }
  return getDefaultAvatar();
}
