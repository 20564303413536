import { createSlice } from "@reduxjs/toolkit";
import { SAVED_LOCALE } from "../../../i18n";
import { LOCAL_STORAGE_LOCALE_KEY } from "../../utils/constants";

const initialState = {
  locale: SAVED_LOCALE,
};

const languageProviderSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    changeLocale(state, action: { payload: string; type: string }) {
      const newLocale = action.payload;
      localStorage?.setItem(LOCAL_STORAGE_LOCALE_KEY, newLocale);
      state.locale = newLocale;
    },
  },
});

// `createSlice` automatically generated action creators with these names.
// export them as named exports from this "slice" file
export const { changeLocale } = languageProviderSlice.actions;

// Export the slice reducer as the default export
export default languageProviderSlice.reducer;
