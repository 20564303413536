import React from "react";

import { styled } from "@mui/material/styles";

import A from "../A";
import Wrapper from "./Wrapper";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocaleToggle from "../../containers/LocaleToggle";
import { FormattedMessage } from "react-intl";

const PREFIX = "Footer";

const classes = {
  love: `${PREFIX}-love`,
};

const StyledWrapper = styled(Wrapper)({
  [`& .${classes.love}`]: {
    color: "red",
  },
});

function Footer() {
  return (
    <StyledWrapper>
      <section>
        <A href="https://github.com/wmundev/scrum-poker-backlog/issues">
          <FormattedMessage id="components.Footer.feedback" />
        </A>
      </section>
      <section>
        <LocaleToggle />
      </section>
      <section>
        <FormattedMessage
          id="components.Footer.author.message"
          values={{
            love: (
              <FavoriteIcon
                classes={{
                  root: classes.love,
                }}
              />
            ),
            author: <A href="https://github.com/wmundev">Wilson Mun</A>,
          }}
        />
      </section>
    </StyledWrapper>
  );
}

export default Footer;
