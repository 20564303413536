/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import React from "react";
import { IntlProvider } from "react-intl";
import { useAppSelector } from "../../hooks";

export interface ILanguageProviderProps {
  messages: {
    [key: string]: any;
  };
  children: any; //React.ReactElement
}

export function LanguageProvider(props: ILanguageProviderProps) {
  const locale = useAppSelector((state) => state.language.locale);

  return (
    <IntlProvider
      locale={locale}
      key={locale}
      messages={props.messages[locale]}
    >
      {React.Children.only(props.children)}
    </IntlProvider>
  );
}

export default LanguageProvider;
