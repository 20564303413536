import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { connectRouter } from "connected-react-router";
import history from "./utils/history";
import languageProviderSliceReducer from "./containers/LanguageProvider/languageProviderSlice";
import registerSliceReducer from "./containers/Register/registerSlice";

export const store = configureStore({
  reducer: {
    router: connectRouter(history),
    language: languageProviderSliceReducer,
    register: registerSliceReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
