/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React from "react";
import { Helmet } from "react-helmet-async";
import { Route, Switch } from "react-router-dom";

import HomePage from "../HomePage/Loadable";
import NotFoundPage from "../NotFoundPage/Loadable";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import PokerRoom from "../../components/PokerRoom/Loadable";
import Register from "../Register/Loadable";

import { ROOM_URL } from "../../utils/constants";
import "./App.scss";

const roomUrl = `${ROOM_URL}/:id`;

export default function App() {
  return (
    <div id="app">
      {
        // @ts-ignore
        <Helmet titleTemplate="%s - Scrum Poker" defaultTitle="Scrum Poker">
          <meta name="description" content="Scrum Poker" />
        </Helmet>
      }
      <Header />
      <Switch>
        <Route exact path="/">
          <HomePage />
        </Route>
        <Route path="/register">
          <Register />
        </Route>
        <Route path={roomUrl}>
          <PokerRoom />
        </Route>
        <Route>
          <NotFoundPage />
        </Route>
      </Switch>
      <Footer />
    </div>
  );
}
