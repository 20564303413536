/**
 *
 * ModalComponent
 *
 */

import React, { memo, useEffect, useState } from "react";
// import styled from 'styled-components';
import Modal from "antd/lib/modal/Modal";

export interface IModalComponentProps {
  title: string;
  show: boolean;
  onModalCancel: () => void;
  onModalOk: () => void;
  children: any;
  okText?: string;
  cancelText?: string;
}

function ModalComponent(props: IModalComponentProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (props.show) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [props.show]);

  const okModal = () => {
    if (props.onModalOk) {
      props.onModalOk();
    }
  };

  const cancelModal = () => {
    setIsModalVisible(false);

    if (props.onModalCancel) {
      props.onModalCancel();
    }
  };

  return (
    <>
      {/*<div>*/}
      {/*  <FormattedMessage {...messages.header} />*/}
      {/*</div>*/}
      <Modal
        title={props.title ? props.title : ""}
        open={isModalVisible}
        onOk={okModal}
        onCancel={cancelModal}
        okText={props.okText ?? "Ok"}
        cancelText={props.cancelText ?? "Cancel"}
      >
        {props.children}
      </Modal>
    </>
  );
}

export default memo(ModalComponent);
