import React, { useEffect, useRef, useState } from "react";
import type { MenuProps } from "antd";
import { Col, Divider, Input, InputRef, Menu, Row } from "antd";
import { Link, useLocation } from "react-router-dom";
import HomeOutlined from "@ant-design/icons/lib/icons/HomeOutlined";
import NavBar from "./NavBar";
import "./Header.css";
import { ROOM_URL } from "../../utils/constants";
import ModalComponent from "../ModalComponent";
import {
  NotificationPlacement,
  NotificationType,
  openNotification,
} from "../../services/Notification";
import QRCode from "react-qr-code";
import { FormattedMessage, useIntl } from "react-intl";
import { ItemType } from "antd/lib/menu/hooks/useItems";

export interface IHeaderProps {}

function Header(props: IHeaderProps) {
  const intl = useIntl();
  const location = useLocation();

  const [currentSelection, setCurrentSelection] = useState<string>("");
  const [showInviteToRoom, setShowInviteToRoom] = useState<boolean>(false);
  const [showInviteToRoomModal, setShowInviteToRoomModal] =
    useState<boolean>(false);
  const [inviteLink, setInviteLink] = useState<string>("");

  const inviteLinkInputElementRef = useRef<InputRef>(null);

  const showInviteMenuItem = (currentLocation: string) => {
    if (currentLocation && currentLocation.includes(ROOM_URL)) {
      setShowInviteToRoom(true);
    } else {
      setShowInviteToRoom(false);
    }
  };

  useEffect(() => {
    const currentLocation = location?.pathname;

    // only show invite link if in a room
    showInviteMenuItem(currentLocation);

    if (currentLocation) {
      setCurrentSelection(currentLocation);
    }

    setInviteLink(window.location.href);
  }, [location]);

  const updateShowInviteToRoomModal = () => {
    setShowInviteToRoomModal(true);
  };

  const hideShowInviteToRoomModal = () => {
    setShowInviteToRoomModal(false);
  };

  const copyShowInviteLinkToClipboard = () => {
    const inviteLinkInputElement = inviteLinkInputElementRef.current;
    if (inviteLinkInputElement) {
      inviteLinkInputElement.select();
      // try copy using old api
      const supportedCommand = document.execCommand("copy");

      if (supportedCommand) {
        openNotification(
          NotificationType.info,
          NotificationPlacement.bottomLeft,
          "Copied to clipboard!",
          undefined,
          5,
        );
      } else {
        // try new api
        navigator.clipboard.writeText(inviteLink).then(() => {
          openNotification(
            NotificationType.info,
            NotificationPlacement.bottomLeft,
            "Copied to clipboard!",
            undefined,
            5,
          );
        });
      }
    }
  };

  const handleClick = (selection: any) => {
    setCurrentSelection(selection.key);
  };

  const addInvitePlayerMenuItem = (items: ItemType[]): ItemType[] => {
    if (!showInviteToRoom) {
      return items;
    }

    return items.concat({
      label: (
        <Col onClick={updateShowInviteToRoomModal}>
          <FormattedMessage id="components.Header.register.inviteplayers" />
        </Col>
      ),
      key: "/invite-players",
    });
  };

  const menuItems: MenuProps["items"] = addInvitePlayerMenuItem([
    {
      label: (
        <Link to="/">
          <HomeOutlined id="header-home-icon" data-testid="header-home-icon" />
        </Link>
      ),
      key: "/",
    },
    {
      label: (
        <Link to="/register">
          <FormattedMessage id="components.Header.register.nav" />
        </Link>
      ),
      key: "/register",
    },
  ]);

  return (
    <div id="header">
      {/* <A href="#"> */}
      {/*  <Img src={Banner} alt="logo" /> */}
      {/* </A> */}
      <NavBar>
        <Menu
          onClick={handleClick}
          selectedKeys={[currentSelection]}
          mode="horizontal"
          items={menuItems}
        />
        <ModalComponent
          title={intl.formatMessage({
            id: "components.Header.register.inviteplayers",
          })}
          show={showInviteToRoomModal}
          onModalCancel={hideShowInviteToRoomModal}
          onModalOk={copyShowInviteLinkToClipboard}
          okText={intl.formatMessage({
            id: "components.Header.modal.ok_action",
          })}
          cancelText={intl.formatMessage({
            id: "components.Header.modal.cancel_action",
          })}
        >
          <Row>
            <Divider orientation="left">
              <FormattedMessage id="components.Header.qrcode.title" />
            </Divider>
            <Col span={24}>
              {
                // @ts-ignore
                <QRCode
                  style={{ display: "block", margin: "0 auto" }}
                  size={96}
                  value={inviteLink}
                />
              }
            </Col>
            <Divider orientation="left" />
            <Col span={24}>
              <Input
                autoFocus
                value={inviteLink}
                ref={inviteLinkInputElementRef}
              />
            </Col>
          </Row>
        </ModalComponent>
        {/* <HeaderLink to="/"> */}
        {/*  <FormattedMessage {...messages.home} /> */}
        {/* </HeaderLink> */}
        {/* <HeaderLink to="/features"> */}
        {/*  <FormattedMessage {...messages.features} /> */}
        {/* </HeaderLink> */}
      </NavBar>
    </div>
  );
}

export default Header;
