/*
 *
 * LanguageToggle
 *
 */

import React from "react";
import Wrapper from "./Wrapper";
import { appLocales } from "../../../i18n";
import { Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import Grid from "@mui/material/Grid";
import { Select } from "antd";
import { changeLocale } from "../LanguageProvider/languageProviderSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";

export interface ILocaleToggleProps {}

const items = appLocales.map((locale) => {
  return {
    label: locale,
    value: locale,
  };
});

export function LocaleToggle(props: ILocaleToggleProps) {
  const dispatch = useAppDispatch();
  const locale = useAppSelector((state) => state.language.locale);

  const onLocaleToggle = (localeSelected: string) => {
    dispatch(changeLocale(localeSelected));
  };

  return (
    <Wrapper>
      <Grid container>
        <Grid item xs={10}>
          <Typography variant="body1">
            <FormattedMessage id="containers.LocaleToggle.title" />
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Select
            defaultValue={appLocales[2]}
            onChange={onLocaleToggle}
            options={items}
            value={locale}
          />
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default LocaleToggle;
