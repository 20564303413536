import React from "react";
// @ts-ignore
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./app/containers/App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, getToken } from "firebase/messaging";
import { getPerformance } from "firebase/performance";
import { getRemoteConfig } from "firebase/remote-config";
import { fetchAndActivate } from "firebase/remote-config";

import {
  NotificationPlacement,
  NotificationType,
  openNotification,
} from "./app/services/Notification";
import LanguageProvider from "./app/containers/LanguageProvider";
import { translationMessages } from "./i18n";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { ConfigProvider, ThemeConfig } from "antd";
import { createTheme, ThemeProvider } from "@mui/material/styles";

try {
  if (process.env.NODE_ENV !== "development") {
    /**
     *  sentry monitoring exceptions
     *  https://docs.sentry.io/platforms/javascript/guides/react/
     * */
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN || "",
      environment: process.env.NODE_ENV,
      release: process.env.REACT_APP_VERSION || "",
      integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 0.2,

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }
} catch (e: any) {
  console.error(e?.message);
}

try {
  // Firebase init
  const firebaseConfig = {
    apiKey: "AIzaSyCcMSRxW06cy8fZzWQh3eEOXeh34qGNHwI",
    authDomain: "scrum-poker-a.firebaseapp.com",
    projectId: "scrum-poker-a",
    storageBucket: "scrum-poker-a.appspot.com",
    messagingSenderId: "135160418642",
    appId: "1:135160418642:web:9f5cd501a0bba037406a8b",
    measurementId: "G-8NQ3JCWMJ3",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  // Initialize Performance Monitoring and get a reference to the service
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const perf = getPerformance(app);

  // Initialize Remote Config and get a reference to the service
  const remoteConfig = getRemoteConfig(app);

  fetchAndActivate(remoteConfig)
    .then((res) => {
      // console.log(res);
    })
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {});

  // Initialize Firebase Cloud Messaging and get a reference to the service
  const messaging = getMessaging(app);
  onMessage(messaging, (payload) => {
    console.log("Message received. ", payload);

    const constructedMessageTitle = payload?.notification?.title ?? undefined;

    if (constructedMessageTitle) {
      const description = payload?.notification?.body ?? "";

      openNotification(
        NotificationType.info,
        NotificationPlacement.bottomLeft,
        constructedMessageTitle,
        description,
        0,
      );
    }
  });

  // Get registration token. Initially this makes a network call, once retrieved
  // subsequent calls to getToken will return from cache.
  getToken(messaging, {
    vapidKey: process.env.REACT_APP_FIREBASE_MESSAGING_VAPID_KEY,
  })
    .then((currentToken) => {
      if (currentToken) {
        // Send the token to your server and update the UI if necessary
        // console.log("token obtained", currentToken);
        // console.log("Firebase messaging connected");
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one.",
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
} catch (e: any) {
  console.error(e?.message);
}

const antDesignTheme: ThemeConfig = {
  token: {
    fontFamily:
      // "Roboto, 'Helvetica Neue', Arial, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
      "'proxima-nova', Roboto, 'Helvetica', Arial, sans-serif",
  },
};

const materialUiTheme = createTheme({
  typography: {
    fontFamily: "'proxima-nova', Roboto, 'Helvetica', Arial, sans-serif",
  },
});

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <Sentry.ErrorBoundary
    fallback={
      <div>An error has occurred, please refresh the page to continue</div>
    }
    showDialog
  >
    <Provider store={store}>
      <LanguageProvider messages={translationMessages}>
        <BrowserRouter>
          {
            // @ts-ignore
            <HelmetProvider>
              <ConfigProvider theme={antDesignTheme}>
                <ThemeProvider theme={materialUiTheme}>
                  <App />
                </ThemeProvider>
              </ConfigProvider>
            </HelmetProvider>
          }
        </BrowserRouter>
      </LanguageProvider>
    </Provider>
  </Sentry.ErrorBoundary>,
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
