/**
 * Asynchronously loads the component for NotFoundPage
 */

import React from "react";
import loadable from "../../utils/loadable";
import LoadingIndicator from "../../components/LoadingIndicator";

export default loadable(() => import("./index"), {
  // @ts-ignore
  fallback: <LoadingIndicator />,
});
