/**
 *
 * Register
 *
 */
import React from "react";
import { Helmet } from "react-helmet-async";
import { Avatar, Button, Checkbox, Col, Form, Input, Row } from "antd";

import "./Register.css";
import { IAvatar, PROFILE_IMAGES } from "../../services/Avatar.service";
import { useHistory, useLocation } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  changeAvatar,
  changeRememberMe,
  changeRoomId,
  changeUsername,
} from "./registerSlice";
import { CheckboxChangeEvent } from "antd/es/checkbox";

export const USERNAME_KEY = "username";
export const AVATAR_KEY = "avatar";
export const ROOM_KEY = "room";

interface RouterState {
  roomId: string;
}
interface RegisterProps {}
interface FormValues {
  username: string;
  room: string;
}
export function Register(props: RegisterProps) {
  const history = useHistory();
  const location = useLocation();
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const rememberMe = useAppSelector((state) => state.register.rememberMe);
  const initialUsername = useAppSelector((state) => state.register.username);
  const roomId = useAppSelector((state) => state.register.roomId);
  const selectedAvatar = useAppSelector((state) => state.register.avatar);

  //if the user came from a redirect from a room url link
  const locationState = location.state as RouterState | undefined;
  const initialRoom = locationState?.roomId ?? roomId;

  const onFinish = (values: FormValues) => {
    const roomToJoin = values.room;
    dispatch(changeUsername(values.username));
    dispatch(changeRoomId(roomToJoin));
    history.push(`/room/${roomToJoin}`);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error("Failed:", errorInfo);
  };

  const rememberMeOnChanged = (e: CheckboxChangeEvent) => {
    dispatch(changeRememberMe(e.target.checked));
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const onChangeSelectedAvatar = (avatar: IAvatar) => {
    dispatch(changeAvatar(avatar));
  };

  return (
    <div>
      {
        // @ts-ignore
        <Helmet>
          <title>Register</title>
          <meta name="description" content="Description of Register" />
        </Helmet>
      }
      {/*<FormattedMessage {...messages.header} />*/}
      <Row>
        <Col>
          <Form
            {...layout}
            name="basic"
            initialValues={{
              remember: rememberMe,
              username: initialUsername,
              room: initialRoom,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label={intl.formatMessage({
                id: "containers.Register.form.avatar",
              })}
            >
              {PROFILE_IMAGES.map((image, key) => {
                return (
                  <Avatar
                    key={key}
                    // @ts-ignore
                    onClick={() => {
                      onChangeSelectedAvatar(image);
                    }}
                    src={image.image}
                    className={
                      selectedAvatar.id === image.id ? "selected-avatar" : ""
                    }
                  />
                );
              })}
            </Form.Item>

            <Form.Item
              label={intl.formatMessage({
                id: "containers.Register.form.username",
              })}
              name="username"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={intl.formatMessage({
                id: "containers.Register.form.room",
              })}
              name="room"
              rules={[
                { required: true, message: "Please input a room to join!" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item name="remember" valuePropName="checked">
              <Checkbox checked={rememberMe} onChange={rememberMeOnChanged}>
                <FormattedMessage id="containers.Register.remember" />
              </Checkbox>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                <FormattedMessage id="containers.Register.submit" />
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default Register;
