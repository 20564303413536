import { LOCAL_STORAGE_LOCALE_KEY } from "./app/utils/constants";
import { getNavigatorLanguage } from "./browser-helper";

export const appLocales = [
  "ar",
  "de",
  "en",
  "es",
  "fa",
  "fr",
  "hi",
  "it",
  "ja",
  "ko",
  "ms",
  "pt",
  "ru",
  "tr",
  "vi",
  "zh",
  "zh-TW",
];

const enTranslationMessages = require("./translations/en.json");

export function getBrowserUserLocale(): string | undefined {
  try {
    const browserLanguage = getNavigatorLanguage();
    if (appLocales.includes(browserLanguage)) {
      return browserLanguage;
    }
  } catch (e) {
    console.error("error occurred when trying to get browser locale", e);
  }
  return undefined;
}

export function getSavedLocale() {
  return (
    localStorage.getItem(LOCAL_STORAGE_LOCALE_KEY) ??
    getBrowserUserLocale() ??
    DEFAULT_LOCALE
  );
}

export const DEFAULT_LOCALE = "en";
export const SAVED_LOCALE = getSavedLocale();

// @ts-ignore
export const formatTranslationMessages = (locale, messages) => {
  // @ts-ignore
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages)
      : {};
  // @ts-ignore
  const flattenFormattedMessages = (formattedMessages, key) => {
    // @ts-ignore
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE
        ? defaultFormattedMessages[key]
        : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  };
  const flattenedFormattedMessages: any = Object.keys(messages).reduce(
    flattenFormattedMessages,
    {}
  );
  return {
    ...defaultFormattedMessages,
    ...flattenedFormattedMessages,
  };
};

export const translationMessages = appLocales.reduce((acc, lan) => {
  const translationMessage = require(`./translations/${lan}.json`);
  // @ts-ignore
  acc[lan] = formatTranslationMessages(lan, translationMessage);
  return acc;
}, {});
