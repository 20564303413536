import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import "./LoadingIndicator.scss";
const LoadingIndicator = () => (
  <div className="loading-indicator">
    <CircularProgress data-testid="loading" />
  </div>
);

export default LoadingIndicator;
